const contentful = require("contentful");

/**
 * clientの設定を行う
 * spaceとaccessTokenを指定
 */
const client = contentful.createClient({
    space: "sn8ya1y863zg",
    accessToken: "bsmx3zM8Jh8Chz4ik7nOJuFZaIoZk084o3pjqbRtctc"
});

/**
 * https://contentful.github.io/contentful.js/contentful/7.14.8/ContentfulClientAPI.html#.getEntry
 */
async function getEntry(entryID) {
    const response = await client.getEntry(entryID);
    return response
}

async function getEntryBySlug(entryID) {
    
    const response = await client.getEntries({
        // limit: 1,
        'fields.slug': entryID,
        content_type: 'news',
        order: 'sys.createdAt'
    });
    return response.items[0]
}
/**
 * https://contentful.github.io/contentful.js/contentful/7.14.8/ContentfulClientAPI.html#.getEntries
 */
async function getEntries(query) {
    const response = await client.getEntries(query);
    return response.items;
}

async function getAssets(ID, query) {
    return await client.getAsset(ID, query);
}

async function getContentTypes(query) {
    return await client.getContentTypes(query);
}

export const contentfulMethods = {getEntry, getEntryBySlug, getEntries, getAssets, getContentTypes}
