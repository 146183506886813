'use strict';
import '@babel/polyfill';
const BREAK_POINT = 1024;

import Action from './_Action.js'
import './_Feeds.js'

window.addEventListener('DOMContentLoaded', async function () {
  new Action(BREAK_POINT);

}, false)
