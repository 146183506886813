'use strict';
import '@babel/polyfill';

require("picturefill");
import 'objectFitPolyfill';
import SmoothScroll from './_SmoothScroll.js'
import ScrollHelper from './_ScrollHelper.js'
import ResizeHelper from './_ResizeHelper.js'
import inView from 'in-view';

class Action {
  constructor(BREAK_POINT){
    const $body = document.querySelector('body')
    $body.classList.add('loaded')
  
    let windowHeight = window.innerHeight;
    new ResizeHelper(() => {
      windowHeight = window.innerHeight;
    })
    new ScrollHelper(function(){
      const is_scrolled = (document.documentElement.scrollTop || document.body.scrollTop) > (windowHeight / 3)
      const is_hidden = (document.documentElement.scrollTop || document.body.scrollTop) > 90
      document.body.classList.toggle('sticky', is_scrolled)
      document.body.classList.toggle('before-sticky', is_hidden)
    })
    
    inView.offset(200)
    inView('.inview')
    .on('enter', e => {
      e.classList.add('is-inview')
    })
  
    document.querySelector('.js-toggle-menu').addEventListener('click', e => {
      $body.classList.toggle('menu-active')
    })
  
    const smoothScroll = new SmoothScroll({
      breakpoint: BREAK_POINT,
      offset: {
        pc: 0,
        sp: (180/window.innerWidth*100)
      }
    });
    smoothScroll.begin = () => {
      if (window.innerWidth < BREAK_POINT) {
        $body.classList.remove('menu-active')
      }
    }
    
    // polyfill
    objectFitPolyfill()
  }
}

export default Action;

