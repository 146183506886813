import { contentfulMethods } from './_Contentful.js'
import dayjs from 'dayjs'
import _ from 'underscore'

// Markdownのパーサー
const MarkdownIt = require('markdown-it')
const md = new MarkdownIt({
  html: true,
  breaks: true,
  linkify: true,
})

// ページに表示させる件数
const limitDefault = 20
const limit = (function () {
  const articlesElement = document.getElementById('news_wrapper')
  if (articlesElement) {
    const limit =
      parseInt(articlesElement.getAttribute('data-length')) || limitDefault
    return limit
  }
})()

const init = async () => {
  try {
    await setEntries()
    await setArticle()
    await setPagination()
  } catch (error) {
    // throwErrorのErrorをcatchできる
    showError(error)
    console.error(error)
  }
}

window.addEventListener(
  'DOMContentLoaded',
  async function () {
    await init()
    const wrapper = document.getElementById('news_wrapper')
    if (wrapper) {
      wrapper.classList.add('loaded')
      const loading = document.getElementById('news_loading')
      if (loading) {
        loading.classList.add('loaded')
      }
    }
  },
  false
)

/**
 * ページネーションを作成して、表示させる
 */
async function setPagination() {
  const paginationElement = document.getElementById('news_pagination')
  const param = getParams()
  const entryID = param.hasOwnProperty('id') ? param.id : null
  if (paginationElement && !entryID) {
    // 全投稿を取得する
    const entries = await contentfulMethods.getEntries({
      order: 'sys.createdAt',
    })

    console.log(JSON.stringify(entries, null, 2))

    // 全投稿から件数を取得して、それをlimitで割って切り上げてpage数を計算する
    const maxPage = Math.ceil(entries.length / limit)
    console.log(
      'maxPage=',
      maxPage,
      'entries.length=',
      entries.length,
      'limit=',
      limit
    )

    // 現在のページを取得
    const param = getParams()
    const nowPage = param.hasOwnProperty('page') ? parseInt(param.page) : 1
    if (isNaN(nowPage)) {
      throw new Error('不正な引数')
    }
    const paginationNodes = createPagination(nowPage, maxPage)

    paginationElement.appendChild(paginationNodes)
  }
}

/**
 * ページネーションタグを生成する
 * <ul>
 *     <li><a href="/?page=1">1</a></li>
 *     ...
 * </ul>
 *
 * @param now
 * @param max
 * @return {HTMLUListElement}
 */
function createPagination(now, max) {
  const paginationElement = document.createElement('div')
  paginationElement.classList.add('pagination')
  const start = Math.max(Math.min(now - 2, max - 4), 1)
  // console.log(Math.max(now - 2, 1))
  const last = Math.min(start + 4, max)

  if (now >= 2) {
    const prevElement = document.createElement('a')
    prevElement.classList.add('prev')
    prevElement.setAttribute('href', '/news/?page=1')
    paginationElement.appendChild(prevElement)
  }

  for (let i = start; i <= last; i++) {
    if (i === now) {
      const currentElement = document.createElement('span')
      currentElement.classList.add('current')
      currentElement.innerText = i.toString()
      paginationElement.appendChild(currentElement)
    } else {
      const linkElement = document.createElement('a')
      linkElement.setAttribute('href', '/news/?page=' + i)
      linkElement.innerText = i.toString()
      paginationElement.appendChild(linkElement)
    }
  }

  // next
  if (now + 2 < max) {
    const nextElement = document.createElement('a')
    nextElement.classList.add('next')
    nextElement.setAttribute('href', '/news/?page=' + max)
    paginationElement.appendChild(nextElement)
  }

  return paginationElement
}

/**
 * 指定したIDで記事の情報を取得する
 * IDはURLのパラメーターに付与されていると、そのIDをもとに情報を取得する
 */
async function setArticle() {
  /**
   * URLにidが指定されている場合は、そのIDの記事情報を取得する
   * URLにidがない場合は、デフォルトのIDで記事情報を取得する
   */
  // ページを作成する
  const wrapperElement = document.getElementById('news_article')
  const param = getParams()
  const entryID = param.hasOwnProperty('id') ? param.id : null

  if (wrapperElement && entryID) {
    const articleElement = document.createElement('article')
    articleElement.classList.add('article', 'single-article')

    const article = await contentfulMethods.getEntryBySlug(entryID)
    document.title = article.fields.title + ' - ' + document.title
    // console.log(article)
    // const article = await contentfulMethods.getEntry(entryID);

    const compiled = _.template(`
      <article class="single-article">
        <header class="single-article__header">
          <h1 class="single-article__title"><%= title %></h1>
          <p class="single-article__date"><%= date %></p>
        </header>
        <div class="single-article__body">
          <% if( description ) { %>
            <%= description %>
          <% } %>
          <% if( image ) { %>
            <img src="<%= image %>" alt="<%= title %>">
          <% } %>
          <%= body %>
        </div>
        <div class="single-article__info">
          <h2>【IoT機器スマートマットを用いた在庫管理・発注自動化ソリューションの概要（ <a href="https://www.smartmat.io">https://www.smartmat.io</a> ）】</h2>
          <p>オフィス・飲食店・工場・流通・病院など幅広い業種のビジネスユーザー向けに、独自IoTデバイス「スマートマット」を用いた、残量管理・自動発注サービスを提供しています。</p>
          <h2>【株式会社スマートショッピング　会社概要】</h2>
          <ul>
            <li>代表取締役：志賀　隆之 / 林　英俊</li>
            <li>本社所在地：〒141-0031 東京都品川区西五反田2-1-22 プラネットビル5階</li>
            <li>事業内容　：通販支援サービスサイトの開発・運営、自動発注・在庫管理IoTソリューションの開発・運営等</li>
            <li>資本金　　：8.0億円（資本準備金等を含む）</li>
            <li>会社設立　：2014年11月</li>
          </ul>
          <h2>【本件に関するお問合せ先】</h2>
          <ul>
            <li>株式会社スマートショッピング　広報PR担当</li>
            <li>メールアドレス：<a href="mailto:contact@smartshopping.co.jp">contact@smartshopping.co.jp</a></li>
          </ul>
        </div>
        <nav class="pager">
          <div id="pager_prev"></div>
          <a class="btn btn-sm btn-secondary all" href="/news/index.html">
          <div class="btn-text">一覧に戻る</div>
          </a>
          <div id="pager_next"></div>
        </nav>
      </article>
    `)

    wrapperElement.innerHTML = compiled({
      title: article.fields.title,
      date: dayjs(article.fields.publishDate).format('YYYY年MM月DD日'),
      description: article.fields.description
        ? md.render(article.fields.description)
        : null,
      image: article.fields.image ? article.fields.image.fields.file.url : null,
      body: md.render(article.fields.body),
    })

    await getNextArticle(article.fields.publishDate)
    await getPrevArticle(article.fields.publishDate)
  }
}
/**
 * 指定したIDで記事の情報を取得する
 * IDはURLのパラメーターに付与されていると、そのIDをもとに情報を取得する
 */
async function showError(error) {
  /**
   * URLにidが指定されている場合は、そのIDの記事情報を取得する
   * URLにidがない場合は、デフォルトのIDで記事情報を取得する
   */
  // ページを作成する
  const wrapperElement = document.getElementById('news_wrapper')
  wrapperElement.classList.add('error')
  wrapperElement.innerHTML = `<p>ページを表示できません。</p><code>${error}</code>`
}

/**
 * 表示している記事から、次の記事へのリンクを作成する
 *
 * @param publishDate
 */
async function getNextArticle(publishDate) {
  const nextElement = document.getElementById('pager_next')
  const compiled = _.template(`
    <a class="btn btn-sm btn-secondary next" href="/news/?id=<%= slug %>">
      <div class="btn-text">次の記事へ</div>
      <div class="btn-arrow">
        <svg viewBox="0 0 7 11">
        <path fill-rule="evenodd" d="M0.793,9.741 L5.035,5.499 L0.793,1.257 L1.501,0.549 L5.742,4.792 L6.450,5.499 L5.742,6.206 L1.501,10.449 L0.793,9.741 Z"></path>
        </svg>
      </div>
    </a>
  `)
  const entries = await contentfulMethods.getEntries({
    'fields.publishDate[lt]': publishDate,
    limit: 1,
    content_type: 'news',
    order: '-fields.publishDate',
  })
  nextElement.innerHTML = entries.length
    ? compiled({ slug: entries[0].fields.slug })
    : `<a class="btn btn-sm btn-secondary next disabled"></a>`

  return
}

/**
 * 表示している記事から、前の記事へのリンクを作成する
 *
 * @param publishDate
 */
async function getPrevArticle(publishDate) {
  const prevElement = document.getElementById('pager_prev')
  const compiled = _.template(`
    <a class="btn btn-sm btn-secondary prev" href="/news/?id=<%= slug %>">
      <div class="btn-text">前の記事へ</div>
      <div class="btn-arrow">
        <svg viewBox="0 0 9 16" width="9px" height="16px">
        <path fill-rule="evenodd" fill="rgb(74, 141, 231)" d="M7.999,15.071 L1.636,8.708 L0.928,8.000 L1.636,7.294 L7.999,0.928 L8.707,1.636 L2.343,8.000 L8.707,14.364 L7.999,15.071 Z"></path>
        </svg>
      </div>
    </a>
  `)
  const entries = await contentfulMethods.getEntries({
    'fields.publishDate[gt]': publishDate,
    limit: 1,
    content_type: 'news',
    order: 'fields.publishDate',
  })
  prevElement.innerHTML = entries.length
    ? compiled({ slug: entries[0].fields.slug })
    : `<a class="btn btn-sm btn-secondary prev disabled"></a>`
  return
}

/**
 * URLからGet Parameterを取得する
 * @return {*}
 */
function getParams() {
  return [...new URLSearchParams(location.search).entries()].reduce(
    (obj, e) => ({ ...obj, [e[0]]: e[1] }),
    {}
  )
}

/**
 * Contentfulから記事一覧を取得して、id=listsのタグの中に挿入する
 * @return {Promise<void>}
 */
async function setEntries() {
  // ページを作成する
  const wrapperElement = document.getElementById('news_articles')
  const param = getParams()
  const entryID = param.hasOwnProperty('id') ? param.id : null

  if (wrapperElement && !entryID) {
    // 現在のページを取得
    const param = getParams()
    const nowPage = param.hasOwnProperty('page') ? parseInt(param.page) : 1
    if (isNaN(nowPage)) {
      throw new Error('不正な引数')
    }

    // 現在のページからスキップする記事件数を算出する
    const skip = (nowPage - 1) * limit
    console.log(param, nowPage, skip, limit)

    const entries = await contentfulMethods.getEntries({
      skip,
      limit: limit,
      content_type: 'news',
      order: '-fields.publishDate',
    })
    if (!entries.length) {
      throw new Error('記事が取得できませんでした。')
    }

    const compiled = _.template(`
      <div class="news-list news-list--lg">
        <% _.each(entries, function(entry, i) { %>
          <a class="news-list__item" href="/news/?id=<%= entry.fields.slug %>">
          <div class="news-list__date"><%= dayjs(entry.fields.publishDate).format('YYYY年MM月DD日') %></div>
          <div class="news-list__title"><%= entry.fields.title %></div></a>
        <% }) %>
      </div>
    `)

    wrapperElement.innerHTML = compiled({
      entries: entries,
      dayjs: dayjs,
    })
  }
}

export const Feeds = {}
