let requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
let running = false;
const FRAME_IN_MS = 1000 / 60;

class ScrollHelper {
  constructor(callback){
    this.callback = callback;
    this.listener = this.process.bind(this);
    window.addEventListener('scroll', this.listener, false);
  }
  process(){
    const callback = this.callback;
    if (!running) {
      running = true;
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(callback);
      } else {
        setTimeout(callback, FRAME_IN_MS);
      }
      running = false;
    }
  }
  destroy(){
    window.removeEventListener('scroll', this.listener);
  }
}

export default ScrollHelper;
